<template>
  <div>
    <p class="display-1 mt-10">Mintatantervek kezelése</p>
    <v-expansion-panels class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-eye-outline</v-icon>
            Mintatantervek láthatósága
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :lg="6" :md="6" :sm="6">
              <v-autocomplete
                label="Szak"
                item-color="uni_blue2"
                color="uni_blue2"
                :items="allFaculty"
                v-model="selectedFaculty"
                item-text="F3"
                item-value="F2"
                :search-input.sync="search"
              >
                <template v-slot:item="{ item }">
                  <p>
                    <span class="m-1">{{ item.F3 }} </span>
                    <span class="m-1">{{ item.F5 }} </span>
                    <span class="m-1">({{ item.F4 }})</span>
                    <span class="m-1">({{ item.F2 }})</span>
                  </p>
                </template>
                <template v-slot:selection="{ item }">
                  <p>
                    <span>{{ item.F3 }} </span>
                    <span>{{ item.F5 }} </span>
                    <span>{{ item.F4 }} </span>
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="12" :lg="3" :md="3" :sm="3">
              <v-btn
                color="uni_blue"
                class="text-light mt-3"
                :disabled="selectedFaculty == null"
                @click="searchSamplePlans"
              >
                Keresés
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card v-if="visibleSamplePlans.length > 0" class="my-2">
      <p class="pa-3">Mintatantervek</p>
      <v-data-table
        :headers="visHeader"
        :items="visibleSamplePlans"
        :items-per-page="5"
        class="mx-3"
        height="400"
        ><template #[`item.actions`]="{ item }">
          <v-switch
            label="Látható"
            color="uni_blue"
            v-model="item.lathato"
            @change="lathatosag(item)"
          ></v-switch> </template
      ></v-data-table>
    </v-card>

    <v-card v-if="invisibleSamplePlans.length > 0" class="my-2">
      <p class="pa-3">Láthatatlan mintatantervek</p>
      <v-row class="mx-3">
        <v-col :cols="12" :lg="6" :md="12" :sm="12">
          <v-text-field
            label="Mintatanterv"
            v-model="invisSearch"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="invisHeader"
        :items="filterInvis"
        :items-per-page="5"
        class="mx-3"
        height="400"
      >
        <template #[`item.actions`]="{ item }">
          <v-switch
            label="Látható"
            color="uni_blue"
            v-model="item.lathato"
            @change="lathatosag(item)"
          ></v-switch> </template
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    allFaculty: [],
    invisibleSamplePlans: [],
    visibleSamplePlans: [],
    selectedFaculty: "",
    invisSearch: "",
    search: "",
    invisHeader: [
      { text: "Mintatanterv kód", value: "mintaterv_kod" },
      { text: "Mintatanterv", value: "mintaterv_nev" },
      { text: "", value: "actions", sortable: false },
    ],
    visHeader: [
      { text: "Mintatanterv kód", value: "F10" },
      { text: "Mintatanterv", value: "F9" },
      { text: "", value: "actions", sortable: false },
    ],
  }),
  mounted() {
    this.getInvisibles();
    // this.getAllFaculty();
  },
  computed: {
    filterInvis() {
      var a = this.invisibleSamplePlans.filter(
        (item) =>
          item.mintaterv_nev
            .toLowerCase()
            .indexOf(this.invisSearch.toLocaleLowerCase()) !== -1
      );
      return a;
    },
  },
  watch: {
    async search(val) {
      try {
        if (val && val.length > 3) {
          var post = {
            search: val,
          };          
          const response = await this.$http.post("/getAllFaculty", post);
          this.allFaculty = response.faculties;
        } else {
          this.allFaculty = [];
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  methods: {
    async lathatosag(item) {
      try {
        const response = await this.$http.post("/updateLathatosag", item);
        if (item.mintaterv_kod) {
          this.visibleSamplePlans.forEach((element) => {
            if (item.mintaterv_kod == element.F9) {
              element.lathato = true;
            }
          });
        }
        this.invisibleSamplePlans = response.sample_plans;
      } catch (e) {
        console.error(e);
      }
    },
    async searchSamplePlans() {
      try {
        var post = {
          F2: this.selectedFaculty,
        };
        const response = await this.$http.post("/searchAdminSamplePlans", post);
        this.visibleSamplePlans = response.sample_plans;
      } catch (e) {
        console.error(e);
      }
    },
    async getInvisibles() {
      try {
        const response = await this.$http.post("/getInvisibles");
        this.invisibleSamplePlans = response.sample_plans;
      } catch (e) {
        console.error(e);
      }
    },
    deleteSPTable() {
      this.visibleSamplePlans = [];
    },
  },
};
</script>

<style>
</style>