var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"display-1 mt-10"},[_vm._v("Mintatantervek kezelése")]),_c('v-expansion-panels',{staticClass:"mt-5"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" Mintatantervek láthatósága ")],1)]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"lg":6,"md":6,"sm":6}},[_c('v-autocomplete',{attrs:{"label":"Szak","item-color":"uni_blue2","color":"uni_blue2","items":_vm.allFaculty,"item-text":"F3","item-value":"F2","search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('span',{staticClass:"m-1"},[_vm._v(_vm._s(item.F3)+" ")]),_c('span',{staticClass:"m-1"},[_vm._v(_vm._s(item.F5)+" ")]),_c('span',{staticClass:"m-1"},[_vm._v("("+_vm._s(item.F4)+")")]),_c('span',{staticClass:"m-1"},[_vm._v("("+_vm._s(item.F2)+")")])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('span',[_vm._v(_vm._s(item.F3)+" ")]),_c('span',[_vm._v(_vm._s(item.F5)+" ")]),_c('span',[_vm._v(_vm._s(item.F4)+" ")])])]}}]),model:{value:(_vm.selectedFaculty),callback:function ($$v) {_vm.selectedFaculty=$$v},expression:"selectedFaculty"}})],1),_c('v-col',{attrs:{"cols":12,"lg":3,"md":3,"sm":3}},[_c('v-btn',{staticClass:"text-light mt-3",attrs:{"color":"uni_blue","disabled":_vm.selectedFaculty == null},on:{"click":_vm.searchSamplePlans}},[_vm._v(" Keresés ")])],1)],1)],1)],1)],1),(_vm.visibleSamplePlans.length > 0)?_c('v-card',{staticClass:"my-2"},[_c('p',{staticClass:"pa-3"},[_vm._v("Mintatantervek")]),_c('v-data-table',{staticClass:"mx-3",attrs:{"headers":_vm.visHeader,"items":_vm.visibleSamplePlans,"items-per-page":5,"height":"400"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"label":"Látható","color":"uni_blue"},on:{"change":function($event){return _vm.lathatosag(item)}},model:{value:(item.lathato),callback:function ($$v) {_vm.$set(item, "lathato", $$v)},expression:"item.lathato"}})]}}],null,true)})],1):_vm._e(),(_vm.invisibleSamplePlans.length > 0)?_c('v-card',{staticClass:"my-2"},[_c('p',{staticClass:"pa-3"},[_vm._v("Láthatatlan mintatantervek")]),_c('v-row',{staticClass:"mx-3"},[_c('v-col',{attrs:{"cols":12,"lg":6,"md":12,"sm":12}},[_c('v-text-field',{attrs:{"label":"Mintatanterv"},model:{value:(_vm.invisSearch),callback:function ($$v) {_vm.invisSearch=$$v},expression:"invisSearch"}})],1)],1),_c('v-data-table',{staticClass:"mx-3",attrs:{"headers":_vm.invisHeader,"items":_vm.filterInvis,"items-per-page":5,"height":"400"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"label":"Látható","color":"uni_blue"},on:{"change":function($event){return _vm.lathatosag(item)}},model:{value:(item.lathato),callback:function ($$v) {_vm.$set(item, "lathato", $$v)},expression:"item.lathato"}})]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }